import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  fetchPersonalDish,
  fetchUserData,
  fetchAnamnesiList
} from "../redux/profile";
import { fetchMenu } from "../redux/menu";
import { setTutorialClear, fetchTutorial } from "../redux/tutorial";

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPersonalDish());
    dispatch(fetchUserData());
    dispatch(fetchMenu());
    dispatch(setTutorialClear());
    dispatch(fetchTutorial());
    dispatch(fetchAnamnesiList());
  }, []);

  return <Navigate to="/percorso/alimentazione" />;
}
