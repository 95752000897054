import React from 'react'

export default function Button(props) {
  return (
    <div style={props.style} className={`button button-${props.type}`} onClick={props.onClick} disabled={props.disabled}>
      {props.icon && <span className='me-2'>{props.icon}</span>}
      <p className='button-text'>{props.name}</p>
    </div>
  )
}
