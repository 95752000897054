import React, { useState } from "react";
import bg from "../assets/bg-fespa.webp";
import Input from "../components/Input";
import Button from "../components/Button";
import { api } from "../api/api";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const getNewCode = async () => {
    api
      .post("/login/restore_password", {
        email: email,
      })
      .then(() => navigate("../resetPassword"))
      .catch(() =>
        setShowPopup({
          visible: true,
          heading: "ATTENTION",
          message: "This email is not registered, try another email",
          button: "Try Again",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        })
      );
  };

  return (
    <div className="login">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <img src={bg} alt="Fespa Background" className="login-bg" />
      <div className="login-wrapper">
        <p className="login-logo">fespa</p>
        <p className="login-description">
          Inserisci l'email utilizzata per registrarti all’app
        </p>
        <Input
          placeholder="Login Email"
          hint="Log-in ricevuto sulla mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <p className="login-help" onClick={() => navigate("../resetPassword")}>
          Resetta la password con il codice
        </p>
        <Button
          name="Invia"
          type="black"
          onClick={() => {
            email
              ? getNewCode()
              : setShowPopup({
                  visible: true,
                  heading: "ATTENTION",
                  message: "All fields are required",
                  button: "Try Again",
                  onClose: () => setShowPopup({ visible: false }),
                  onClick: () => setShowPopup({ visible: false }),
                });
          }}
        />
      </div>
    </div>
  );
}
