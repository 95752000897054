import React, { useState } from "react";
import bg from "../assets/bg-fespa.webp";
import Input from "../components/Input";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import { api, apiUrl } from "../api/api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Popup from "../components/Popup";

export default function RegisterClient() {
  const navigate = useNavigate();
  const email = useSelector((state) => state.profile.registrationEmail);
  const code = useSelector((state) => state.profile.registrationCode);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isChecked, setChecked] = useState(false);
  const info = {
    date: moment(),
  };
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkPassword = () => newPassword && newPassword === repeatPassword;

  const sendInfo = () => {
    var formdata = new FormData();
    formdata.append("object", JSON.stringify(info));
    formdata.append("code", JSON.stringify(code));

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/login/add_docs`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error from sending info", error));
  };

  const registerUser = async () => {
    if (isChecked) {
      api
        .post("/login/activation_client", {
          email: email,
          password: newPassword,
          code: code,
        })
        .then((response) => {
          if (response.data.msg === "Bad email or code") {
            setShowPopup({
              visible: true,
              heading: "ATTENTION",
              message: "Wrong email or activation code",
              button: "Try Again",
              onClose: () => setShowPopup({ visible: false }),
              onClick: () => navigate(-1),
            });
          } else {
            sendInfo();
            setShowPopup({
              visible: true,
              heading: "Account successfully activated",
              message: "Please log in with your email and password!",
              button: "OK",
              onClose: () => setShowPopup({ visible: false }),
              onClick: () => navigate(-2),
            });
          }
        })
        .catch((err) => {
          setShowPopup({
            visible: true,
            heading: "ATTENTION",
            message: "Wrong email or activation code",
            button: "Try Again",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => navigate(-1),
          });
        });
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENTION",
        message:
          "Per continuare la registrazione, bisogna accettare i Termini e Condizioni",
        button: "Try Again",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });
    }
  };

  return (
    <div className="login">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <img src={bg} alt="Fespa Background" className="login-bg" />
      <div className="login-wrapper">
        <p className="login-logo">fespa</p>
        <p className="login-description">Imposta una nuova password</p>
        <Input
          placeholder="Nuova Password"
          hint="New Password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          placeholder="Ripeti la nuova Password"
          hint="Repeat new Password"
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
        <div className="d-flex align-items-center mb-3">
          <input
            type="checkbox"
            className="login-checkbox"
            onChange={() => setChecked(!isChecked)}
            value={isChecked}
          />
          <p className="login-checkbox-label">Accetto Termini e Condizioni</p>
        </div>
        <Button
          name="Register"
          type="black"
          onClick={() => {
            checkPassword()
              ? registerUser()
              : setShowPopup({
                  visible: true,
                  heading: "ATTENTION",
                  message: "Repeat new password correctly and try again",
                  button: "Try Again",
                  onClose: () => setShowPopup({ visible: false }),
                  onClick: () => setShowPopup({ visible: false }),
                });
          }}
        />
      </div>
    </div>
  );
}
