import React, { useState } from "react";
import bg from "../assets/bg-fespa.webp";
import Input from "../components/Input";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRegistrationEmail, setRegistrationCode } from "../redux/profile";
import Popup from "../components/Popup";

export default function ActivationClient() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkCode = () => {
    if (email && code) {
      dispatch(setRegistrationEmail(email));
      dispatch(setRegistrationCode(code));
      navigate("../register");
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENTION",
        message: "All field are required!",
        button: "Try Again",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });
    }
  };

  return (
    <div className="login">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <img src={bg} alt="Fespa Background" className="login-bg" />
      <div className="login-wrapper">
        <p className="login-logo">fespa</p>
        <p className="login-description">
        Inserisci le credenziali di accesso che ti sono state inviate sulla mail
        </p>
        <Input
          placeholder="Login Email"
          hint="Log-in ricevuto sulla mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder="Codice a 6 cifre"
          hint="codice temporaneo ricevuto sulla mail"
          onChange={(e) => setCode(e.target.value)}
        />
        <Button name="Next" type="black" onClick={() => checkCode()} />
      </div>
    </div>
  );
}
