import React, { useEffect, useState } from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../redux/profile";
import { api, apiUrl } from "../api/api"; 
import { logout } from "../redux/auth";
import Button from "../components/Button";
import Card from "../components/Card";
import ImagePicker from "../components/ImagePicker";
import InformationCard from "../components/InformationCard";
import Loader from "../components/Loader";
import ChangePasswordModal from "../components/ChangePasswordModal";
import { fetchLegacyAll, setLegacyItem } from "../redux/legacy";
import { truncateString } from "../helper/truncateString";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);
  const token = useSelector((state) => state.auth.token);
  const userLoading = useSelector((state) => state.profile.userLoading);
  const userContract = useSelector((state) => state.profile.userContract);
  const legacyAll = useSelector((state) => state.legacy.list);
  const legacyItem = useSelector((state) => state.legacy.item);
  const [profileShown, setProfileShown] = useState(true);
  const [legacyShown, setLegacyShown] = useState(false);

  useEffect(() => {
    dispatch(fetchUserData());
    dispatch(fetchLegacyAll());
  }, []);

  const uploadImage = async (profileImg) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("img[]", profileImg, profileImg.name);
    formdata.append("type", "checkParam");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${apiUrl}/img/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => updateProfile(data.id.response[0]))
      .catch((error) => console.log("error", error));
  };

  const updateProfile = (imageId) => {
    const jsonObject = {
      json_object: {
        alimentazione: user.alimentazione,
        allenamento: user.allenamento,
        name: user.name,
        phone: user.phone,
        email: user.email,
        age: user.age,
        address: user.address,
        comune: user.comune,
        provincia: user.provincia,
        cap: user.cap,
        image: imageId,
      },
      image: imageId,
    };

    api
      .post("/profile/update_me", jsonObject)
      .then(() => {
        dispatch(fetchUserData());
        console.log("Profile Updated");
      })
      .catch((error) => console.log("Error Updating profile", error));
  };

  if (userLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={userLoading} size="50" />
      </div>
    );
  }

  return (
    <IgnoreHeader>
      <div className="container profile pb-3">
        <div className="row justify-content-md-center">
          <div className="col-3 col-xl-3 col-lg-4 col-md-6">
            <Card style={{ padding: 0, overflow: "hidden" }}>
              <div
                onClick={() => {
                  setProfileShown(true);
                  setLegacyShown(false);
                }}
                className={
                  profileShown
                    ? "profile-item profile-item-active"
                    : "profile-item"
                }
              >
                <p
                  className={
                    profileShown
                      ? "profile-item-text profile-item-text-active"
                      : "profile-item-text"
                  }
                >
                  Profile
                  <HiChevronRight size={20} />
                </p>
              </div>
              {legacyAll.map(
                (item, index) =>
                  item.category !== "Contract" && (
                    <div
                      key={index}
                      onClick={() => {
                        dispatch(setLegacyItem(item));
                        setProfileShown(false);
                        setLegacyShown(true);
                      }}
                      className={
                        legacyShown && item.id === legacyItem.id
                          ? "profile-item profile-item-active"
                          : "profile-item"
                      }
                    >
                      <p
                        className={
                          legacyShown && item.id === legacyItem.id
                            ? "profile-item-text profile-item-text-active"
                            : "profile-item-text"
                        }
                      >
                        {truncateString(item.category, 30)}
                        <HiChevronRight size={20} />
                      </p>
                    </div>
                  )
              )}
            </Card>
          </div>
          <div className="col-9 col-xl-9 col-lg-8 col-md-12">
            <Card>
              {legacyShown && (
                <div dangerouslySetInnerHTML={{ __html: legacyItem.content }} />
              )}
              {profileShown && (
                <div className="row justify-content-md-center">
                  <div className="col-9 col-xl-9 col-md-12">
                    <div>
                      <p className="heading mb-4">Il Mio Profilo</p>
                      <div className="row justify-content-md-center">
                        <div className="col-4 col-xl-4 col-lg-12 col-md-12">
                          <div className="profile-image-wrapper">
                            <ImagePicker
                              onImageTaken={(imagePath) =>
                                uploadImage(imagePath)
                              }
                              profile
                            />
                          </div>
                        </div>
                        <div className="col-8 col-xl-8 mt-md-3">
                          <p className="heading heading-card">
                            Dati profilo personale
                          </p>
                          <InformationCard
                            placeholder="Nome e Cognome"
                            info={user?.name}
                          />
                          <InformationCard
                            placeholder="Telefono"
                            info={user?.phone}
                          />
                          <InformationCard
                            placeholder="Email"
                            info={user?.email}
                          />
                          <InformationCard placeholder="Età" info={user?.age} />
                          <InformationCard
                            placeholder="Indirizzo"
                            info={user?.address}
                          />
                          <InformationCard
                            placeholder="Comune"
                            info={user?.comune}
                          />
                          <div className="d-flex justify-content-between align-items-center">
                            <InformationCard
                              placeholder="Provincia"
                              info={user?.provincia}
                            />
                            <InformationCard
                              placeholder="Cap"
                              info={user?.cap}
                            />
                          </div>
                          <Button
                            type="primary"
                            name="Logout"
                            style={{ marginTop: 30 }}
                            onClick={() => dispatch(logout())}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 col-xl-3 col-md-8 mt-md-3 justify-content-md-center">
                    <div className="d-flex justify-content-md-center">
                      <ChangePasswordModal
                        openModal={
                          <Button type="outline" name="Cambia Password" />
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-md-center mt-3">
                      <a
                        href={`${apiUrl}/login/my_contract/?id=${userContract}`}
                        target="_blank"
                        style={{ width: "100%" }}
                      >
                        <Button type="primary" name="Contratto" />
                      </a>
                    </div>
                    <div className="d-flex justify-content-md-center mt-3">
                      <Button type="outline" name="Le mie fatture" onClick={() => navigate('invoices') } />
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </IgnoreHeader>
  );
}
