import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import ProductVideoCard from "../components/ProductVideoCard";
import ProductBookCard from "../components/ProductBookCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVideoCourseAll,
  fetchVideoCourseById,
  setCourseType,
  setVideoCourseLoading,
} from "../redux/videoCourses";
import { fetchPersonalProduct } from "../redux/profile";
import {
  fetchBooks,
  setBookItem,
  setBooksLoading,
  setBookType,
} from "../redux/books";
import Loader from "../components/Loader";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

export default function Disponibili() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [corsi, setCorsi] = useState(true);
  const [libri, setLibri] = useState(false);

  useEffect(() => {
    dispatch(setBooksLoading(true));
    dispatch(setVideoCourseLoading(true));
    dispatch(fetchBooks());
    dispatch(fetchVideoCourseAll());
    dispatch(fetchPersonalProduct());
  }, []);

  return (
    <React.Fragment>
      {location.pathname == "/percorso/disponibili" && (
        <div className="container prodottiExtra">
          <Card>
            <div className="d-flex align-items-center justify-content-center">
              <div
                className={
                  corsi
                    ? "prodottiExtra-nav prodottiExtra-nav-active"
                    : "prodottiExtra-nav"
                }
                onClick={() => {
                  setCorsi(!corsi);
                  setLibri(!libri);
                }}
              >
                <p
                  className={
                    corsi
                      ? "prodottiExtra-nav-text prodottiExtra-nav-text-active"
                      : "prodottiExtra-nav-text"
                  }
                >
                  Corsi
                </p>
              </div>
              <div
                className={
                  libri
                    ? "prodottiExtra-nav prodottiExtra-nav-active"
                    : "prodottiExtra-nav"
                }
                onClick={() => {
                  setCorsi(!corsi);
                  setLibri(!libri);
                }}
              >
                <p
                  className={
                    libri
                      ? "prodottiExtra-nav-text prodottiExtra-nav-text-active"
                      : "prodottiExtra-nav-text"
                  }
                >
                  Libri
                </p>
              </div>
            </div>
          </Card>
          <div>{corsi ? <Corsi /> : <Libri />}</div>
        </div>
      )}

      <Outlet />
    </React.Fragment>
  );
}

const Corsi = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const videoCourse = useSelector((state) => state.videoCourse.list);
  const videoCourseLoading = useSelector((state) => state.videoCourse.loading);
  const personalProducts = useSelector(
    (state) => state.profile.personalProducts
  );

  if (videoCourseLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={videoCourseLoading} size={50} />
      </div>
    );
  }

  if (!personalProducts || personalProducts.length === 0) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <p className="heading">No items available at the moment</p>
      </div>
    );
  }

  const getVideoList = () => {
    if (videoCourse.length < 0) return [];

    const videoArr = [];

    videoCourse.forEach((el) => {
      personalProducts.forEach((personalItem) => {
        if (
          +el.id === +personalItem.split("-")[1] &&
          personalItem.split("-")[0] === "Video Corsi"
        ) {
          videoArr.push(el);
        }
      });
    });

    return videoArr.sort(() => Math.random() - 0.5);
  };

  const setCourse = (course) => {
    dispatch(setVideoCourseLoading(true));
    dispatch(setCourseType("myItem"));
    dispatch(fetchVideoCourseById(course.id));
    navigate("myCourseDetails");
  };

  return (
    <Card>
      <div className="prodottiExtra-wrapper">
        <div className="d-flex align-items-center justify-content-evenly flex-wrap">
          {getVideoList()?.length !== 0 &&
            getVideoList().map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setCourse(item);
                }}
              >
                <ProductVideoCard
                  idImg={item.image || ""}
                  label={"Video corso"}
                  name={item.name || ""}
                  contenuti={item.contenuti && `contenuti ${item.contenuti}`}
                  video={item.count_video && `video ${item.count_video}`}
                  // price={item.start_price && `€${item.start_price}`}
                  // firstPrice={
                  //   item.final_price !== item.start_price &&
                  //   `al posto di €${item.final_price}`
                  // }
                />
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};

const Libri = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const books = useSelector((state) => state.books.list);
  const booksLoading = useSelector((state) => state.books.loading);
  const personalProducts = useSelector(
    (state) => state.profile.personalProducts
  );

  if (booksLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={booksLoading} size={50} />
      </div>
    );
  }

  if (!personalProducts || personalProducts.length === 0) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <p className="heading">No items available at the moment</p>
      </div>
    );
  }

  const getBooksList = () => {
    if (books.length < 0) return [];

    const booksArr = [];

    books.forEach((el) => {
      personalProducts.forEach((personalItem) => {
        if (
          +el.id === +personalItem.split("-")[1] &&
          personalItem.split("-")[0] === "Libri"
        ) {
          booksArr.push(el);
        }
      });
    });

    return booksArr.sort(() => Math.random() - 0.5);
  };

  const setBook = (book) => {
    dispatch(setBookType("myItem"));
    dispatch(setBookItem(book));
    navigate("myBookDetails");
  };

  return (
    <Card>
      <div className="prodottiExtra-wrapper">
        <div className="d-flex align-items-center justify-content-evenly flex-wrap">
          {getBooksList()?.length !== 0 &&
            getBooksList().map((item, index) => (
              <div key={index} onClick={() => setBook(item)}>
                <ProductBookCard
                  idImg={item.image}
                  label={"Libro"}
                  name={item.name || ""}
                />
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};
