import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import Colors from "../constants/Colors";
import Label from "../components/Label";
import Button from "../components/Button";
import VideoPlayer from "../components/VideoPlayer";
import { renderImg } from "../api/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSessione,
  sessioneActive,
  setSessioneLoading,
  setSessioneCategory,
  setSessioneCategoryLoading,
} from "../redux/profile";
import { fetchExerciseById, setExerciseLoading } from "../redux/exercise";
import { checkCategory } from "../helper/categoryName";
import Loader from "../components/Loader";
import ClosedContentCard from "../components/ClosedContentCard";
import { IoPlayOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import IntroAllenamentiModal from "../components/IntroAllenamentiModal";
import { api } from "../api/api";
import moment from "moment";

export default function Allenamenti(props) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.profile.userType);
  const userStatus = useSelector((state) => state.profile.userStatus);
  const sessione = useSelector((state) => state.profile.sessione);
  const sessioneLoading = useSelector((state) => state.profile.sessioneLoading);
  const sessioneCategory = useSelector(
    (state) => state.profile.sessioneCategory
  );
  const sessioneCategoryLoading = useSelector(
    (state) => state.profile.sessioneCategoryLoading
  );
  const exerciseItem = useSelector((state) => state.exercise.item);
  const sessioneActiveItem = useSelector(
    (state) => state.profile.sessioneActive
  );
  const exerciseLoading = useSelector((state) => state.exercise.loading);
  const [coverImage, setCoverImage] = useState();
  const [exIndex, setExIndex] = useState(0);
  const [finished, setFinished] = useState(false);
  const [addNote, setAddNote] = useState(true);
  const [noteInput, setNoteInput] = useState("");
  const [editNotes, setEditNotes] = useState(false);

  useEffect(() => {
    const getNotes = async () => {
      try {
        const value = await localStorage.getItem(
          `allenamentiNote-${exerciseItem.id}`
        );
        setNoteInput(value);
        if (value) {
          setAddNote(false);
        }
      } catch (e) {
        // error reading value
      }
    };
    getNotes();
  }, [exerciseItem.id]);

  useEffect(() => {
    if (!exerciseItem) return;
    getImage(exerciseItem.image);
  }, [exerciseItem]);

  const getImage = (imageId) => {
    if (!imageId) return;
    renderImg(imageId).then((res) => {
      setCoverImage(res);
    });
  };

  useEffect(() => {
    dispatch(setSessioneLoading(true));
    dispatch(fetchSessione());
  }, []);

  useEffect(() => {
    if (!sessioneLoading) {
      dispatch(setSessioneCategoryLoading(true));
      dispatch(setSessioneCategory(sessione?.session_json[0]));
    }
  }, [sessione]);

  useEffect(() => {
    if (!sessioneCategoryLoading) {
      dispatch(
        sessioneActive({
          exerciziItem: sessioneCategory?.eserciziInfo[0],
          index: 0,
          sessioneIndex: 0,
        })
      );
      dispatch(setExerciseLoading(true));
      dispatch(fetchExerciseById(sessioneCategory?.eserciziInfo[0].id));
    }
  }, [sessioneCategory]);

  if (userType !== "Vincente" || userStatus !== "Attivo") {
    return (
      <ClosedContentCard
        heading="Programma Allenamenti"
        content="Richiedi maggiori informazioni sui nostri percorsi di allenamento personalizzati"
        buttonText="Watch Video"
      />
    );
  }

  if (sessioneLoading || sessioneCategoryLoading || exerciseLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader
          loading={
            sessioneLoading || sessioneCategoryLoading || exerciseLoading
          }
          size={50}
        />
      </div>
    );
  }

  const sessioneList = sessioneCategory; //changed for web

  const prev = () => {
    if (
      sessioneList?.eserciziInfo.length > sessioneActiveItem?.index &&
      sessioneActiveItem?.index > 0
    ) {
      const index = +sessioneActiveItem?.index - 1;
      changeSession(index);
    }
  };

  const next = () => {
    if (
      sessioneList?.eserciziInfo.length - 1 > sessioneActiveItem?.index &&
      sessioneActiveItem?.index >= 0
    ) {
      const index = +sessioneActiveItem?.index + 1;
      changeSession(index);
    }
  };

  const checkNext = () => {
    return sessioneList?.eserciziInfo.length - 1 <= sessioneActiveItem?.index;
  };
  const checkPrev = () => {
    return sessioneActiveItem?.index <= 0;
  };

  const changeSession = (index) => {
    setAddNote(true);

    const exerciziItem = sessioneList?.eserciziInfo[index];

    dispatch(
      sessioneActive({
        exerciziItem,
        index,
        sessioneIndex: sessioneActiveItem?.sessioneIndex,
      })
    );
    dispatch(setExerciseLoading(true));
    dispatch(fetchExerciseById(exerciziItem?.id));
  };

  const storeNotes = async (value) => {
    try {
      await localStorage.setItem(`allenamentiNote-${exerciseItem.id}`, value);
    } catch (e) {}
  };

  const finishedSession = () => {
    const categoryIndex = sessione.session_json.findIndex(
      (el) => el.category === sessioneCategory.category
    );
    const now = moment();
    setFinished(true);
    api
      .post("/profile/exercise_done", {
        object: JSON.stringify({
          index: categoryIndex + 1,
          time: now,
        }),
      })
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err));
  };

  return (
    <div className="container allenamenti pb-3">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 pb-3">
          <Card style={{ height: "100%" }}>
            {sessione?.session_json.map((item, index) => (
              <div
                key={index}
                className={
                  index === exIndex
                    ? "allenamenti-sessione-container_active mb-3"
                    : "allenamenti-sessione-container mb-3"
                }
                onClick={() => {
                  dispatch(setSessioneCategoryLoading(true));
                  dispatch(setSessioneCategory(item));
                  setExIndex(index);
                  setFinished(false);
                }}
              >
                <p
                  className={
                    index === exIndex
                      ? "allenamenti-sessione-text_active"
                      : "allenamenti-sessione-text"
                  }
                >{`Sessione ${checkCategory(item.category)}`}</p>
              </div>
            ))}
          </Card>
        </div>
        <div className="col-xl-10 col-lg-10 col-md-12">
          <Card>
            <div className="allenamenti-content-wrapper">
              <div className="d-flex justify-content-evenly mb-3">
                {sessioneCategory?.eserciziInfo.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      index !== sessioneActiveItem.index
                        ? changeSession(index) ///
                        : null
                    }
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className={
                        index === sessioneActiveItem.index
                          ? "allenamenti-pag allenamenti-pag_active"
                          : "allenamenti-pag"
                      }
                    >
                      <p
                        className={
                          index === sessioneActiveItem.index
                            ? "allenamenti-pag-text allenamenti-pag-text_active"
                            : "allenamenti-pag-text"
                        }
                      >
                        {index + 1}
                      </p>
                    </div>
                    <p
                      className={
                        index === sessioneActiveItem.index
                          ? "allenamenti-pag-ex allenamenti-pag-ex_active"
                          : "allenamenti-pag-ex"
                      }
                    >
                      Esercizio
                    </p>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <p className="heading mb-0 me-3">{exerciseItem?.name}</p>
                  <Label
                    labelText={checkCategory(sessioneCategory?.category)}
                    labelColor={Colors.green}
                  />
                </div>
                <IntroAllenamentiModal
                  openModal={
                    <div className="d-flex align-items-center allenamenti-video-intro">
                      <p className="allenamenti-video-text">
                        Video Introduzione
                      </p>
                      <IoPlayOutline size={25} color={Colors.primary} />
                    </div>
                  }
                />
              </div>
              <Card
                style={{
                  padding: 0,
                  overflow: "hidden",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <VideoPlayer link={exerciseItem?.link} />
              </Card>
              <div className="row">
                <div className="col-6">
                  <div className="d-flex">
                    <div className="allenamenti-items">
                      <p className="allenamenti-items-text">Serie</p>
                      <p className="allenamenti-items-content">
                        {sessioneActiveItem?.exerciziItem?.serie || ""}
                      </p>
                    </div>
                    <div className="allenamenti-items">
                      <p className="allenamenti-items-text">ripetizioni</p>
                      <p className="allenamenti-items-content">
                        {sessioneActiveItem?.exerciziItem?.ripetizioni || ""}
                      </p>
                    </div>
                    <div className="allenamenti-items">
                      <p className="allenamenti-items-text">recupero</p>
                      <p className="allenamenti-items-content">
                        {sessioneActiveItem?.exerciziItem?.recupero || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="d-flex justify-content-xl-end justify-content-lg-end">
                    <div className="me-2">
                      <Button
                        name={"Precedente"}
                        type={"outline"}
                        onClick={() => prev()}
                        style={{ opacity: checkPrev() ? 0.5 : 1 }}
                      />
                    </div>
                    <div>
                      <Button
                        name={
                          !checkNext() ? (
                            "Prossimo Video"
                          ) : finished ? (
                            <FaCheck />
                          ) : (
                            "Fine"
                          )
                        }
                        type={"primary"}
                        onClick={() =>
                          checkNext() ? finishedSession() : next()
                        }
                        style={{
                          opacity: !checkNext() ? 1 : finished ? 0.5 : 1,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {sessioneActiveItem?.exerciziItem?.note && (
                <Card>
                  <p className="heading heading-card mb-3">Note</p>
                  <p className="contentText">
                    {sessioneActiveItem?.exerciziItem?.note || ""}
                  </p>
                </Card>
              )}

              <Card>
                <p className="heading heading-card mb-3">Descrizione</p>
                <p className="contentText">{exerciseItem?.notes || ""}</p>
              </Card>
              <div>
                {addNote ? (
                  <Button
                    type="outline"
                    name="+ Aggiungi Note"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setAddNote(false);
                      setEditNotes(true);
                    }}
                  />
                ) : (
                  <Card>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        marginBottom: 10,
                        minHeight: 30,
                      }}
                    >
                      <p className="heading heading-card">
                        {editNotes ? "Aggiungi Note" : "Note"}
                      </p>
                      {editNotes ? (
                        <div
                          onClick={() => {
                            storeNotes(noteInput);
                            setEditNotes(false);
                          }}
                        >
                          <p className="allenamenti-saveButton">SALVA</p>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <FiEdit3
                            size={20}
                            color={Colors.primary}
                            onClick={() => {
                              setEditNotes(true);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <FiTrash2
                            size={20}
                            color={Colors.primary}
                            onClick={() => {
                              setNoteInput("");
                              storeNotes("");
                              setAddNote(true);
                            }}
                            style={{ marginLeft: 15, cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {editNotes ? (
                        <input
                          value={noteInput}
                          placeholder="scrivi qualcosa"
                          onChange={(e) => setNoteInput(e.target.value)}
                          className="allenamenti-noteInput contentText"
                        />
                      ) : (
                        <p className="contentText">{noteInput}</p>
                      )}
                    </div>
                  </Card>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
