import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { FaWindowClose } from "react-icons/fa";
import Card from "../components/Card";
import ProgressCircle from "../components/ProgressCircle";
import Colors from "../constants/Colors";
import Button from "../components/Button";
import Filter from "../components/Filter";
import { api, apiUrl } from "../api/api";
import { fetchAllergyFilter } from "../redux/filter";
import { fetchPersonalDish, setPersonalDishLoading } from "../redux/profile";
import { fetchFavorites } from "../redux/favorites";
import {
  setCategoryMenu,
  setGeneratedMenu,
  setMenuEmpty,
  removeDish,
  setItemModalDish,
  setItemModalFood,
  removeFood,
  fetchCustomCategoryMenu,
} from "../redux/menu";
import {
  sumCarbs,
  sumEnergy,
  sumGras,
  sumProte,
} from "../helper/calc/macrosSum";
import AddDish from "../components/AddDish";
import SelectPlate from "./SelectPlate";
import DishCard from "../components/DishCard";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DishDetails from "../components/DishDetails";
import AlimentiDetails from "../components/AlimentiDetails";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import ClosedContentCard from "../components/ClosedContentCard";
import { IoPlayOutline } from "react-icons/io5";
import IntroAlimentazioneModal from "../components/IntroAlimentazioneModal";
import { fetchCustomFood } from "../redux/food";
import { fetchCustomDish } from "../redux/dish";
import moment from "moment";

export default function Alimentazione() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const userType = useSelector((state) => state.profile.userType);
  const userStatus = useSelector((state) => state.profile.userStatus);
  const userContract = useSelector((state) => state.profile.userContract);
  const personalDish = useSelector((state) => state.profile.personalDish);
  const personalDishLoading = useSelector(
    (state) => state.profile.personalDishLoading
  );
  const refeedDay = useSelector((state) => state.profile.refeedDay);
  const menu = useSelector((state) => state.menu.menu);
  const menuDish = useSelector((state) => state.menu.menu.menuDish);
  const menuFood = useSelector((state) => state.menu.menu.menuFood);
  const favorites = useSelector((state) => state.favorites.favorites);
  const favoriteDish = useSelector(
    (state) => state.favorites.favorites.favoriteDish
  );
  const favoriteFood = useSelector(
    (state) => state.favorites.favorites.favoriteFood
  );
  const activeAllergy = useSelector((state) => state.filter.activeAllergy);
  const listOrEmpty = Object.keys(activeAllergy).length === 0 ? "off" : "list";
  const [generatedLoading, setGeneratedLoading] = useState(false);
  const customCategoryMenu = useSelector(
    (state) => state.menu.customCategoryMenu
  );
  const [customCategoryMenuItem, setCustomCategoryMenuItem] =
    useState("Personalizzato");
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  useEffect(() => {
    dispatch(setPersonalDishLoading(true));
    dispatch(fetchPersonalDish());
    dispatch(fetchAllergyFilter());
    dispatch(fetchFavorites());
    dispatch(fetchCustomFood());
    dispatch(fetchCustomDish());
    dispatch(fetchCustomCategoryMenu());
  }, []);

  useEffect(() => {
    api
      .post("/profile/change_my_menu", { json: menu })
      .then(() => console.log("Menu list uploaded"))
      .catch((err) => console.log("error uploading", err));

    //dispatch(setPersonalDishLoading(true));
    dispatch(fetchPersonalDish());
  }, [menuDish, menuFood]);

  useEffect(() => {
    api
      .post("/profile/change_preferated_dish", {
        preferated_dish: favorites,
      })
      .then((response) => console.log("Favorites list uploaded"))
      .catch((err) => console.log("error favorites"));
  }, [favoriteDish, favoriteFood]);

  if (personalDishLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={personalDishLoading} size={50} />
      </div>
    );
  }

  if (userType !== "Vincente" || userStatus !== "Attivo") {
    return (
      <div>
        <ClosedContentCard
          heading="Strategia Alimentare"
          content="Scopri il nostro percorso e raggiungi la tua liberta alimentare."
          buttonText="Watch Video"
        />
        {userStatus === "Moderations" && !userContract && (
          <div style={{ width: "30%", margin: "0 auto" }}>
            <Card>
              <p className="heading text-center">
                Per continuare ad usare l’app di Fespa è necessario prendere
                visione ed accettare i termini e le condizioni di utilizzo
              </p>
              <Button
                type="primary"
                name="ACCETTA TERMINI E CONDIZIONI"
                onClick={() => navigate("../../termini")}
              />
            </Card>
          </div>
        )}
      </div>
    );
  }

  const generateCustomMenu = () => {
    api
      .post("/profile/get_m_byPersonas", {
        json: {
          id: personalDish.id,
          categoryName: customCategoryMenuItem,
          conjuction: "or",
          allergenContent: JSON.stringify({ data: activeAllergy }),
        },
      })
      .then((result) => {
        const res = result.data.menu;
        setGeneratedLoading(false);
        const collazione = [];
        const pranzo = [];
        const cena = [];
        const snack = [];

        res && res.breakfast && res.breakfast.map((el) => collazione.push(el));
        res && res.lunch && res.lunch.map((el) => pranzo.push(el));
        res && res.dinner && res.dinner.map((el) => cena.push(el));
        res && res.perekus1 && res.perekus1.map((el) => snack.push(el));
        res && res.perekus2 && res.perekus2.map((el) => snack.push(el));

        !res &&
          setShowPopup({
            visible: true,
            heading: "ATTENZIONE",
            message: "Stiamo cercando dei piatti migliori per te!",
            button: "Riprova",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => {
              generateCustomMenu();
              setGeneratedLoading(true);
              setShowPopup({ visible: false });
            },
          });

        dispatch(setMenuEmpty());
        dispatch(setGeneratedMenu({ collazione, pranzo, cena, snack }));
      })
      .catch((error) => {
        setGeneratedLoading(false);
        console.log("error", error.message);
        setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Stiamo cercando dei piatti migliori per te!",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            generateCustomMenu();
            setGeneratedLoading(true);
            setShowPopup({ visible: false });
          },
        });
      });
  };

  // const generateCustomMenuOriginal = () => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `https://api-v2.metodofespa-app.com/api/login/MenuByPersonasAndCategory_web/?id=${
  //       personalDish.id
  //     }&categoryName=${customCategoryMenuItem}&conjuction=or&allergenContent=${JSON.stringify(
  //       { data: activeAllergy }
  //     )}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       const res = result.menu;
  //       setGeneratedLoading(false);
  //       const collazione = [];
  //       const pranzo = [];
  //       const cena = [];
  //       const snack = [];

  //       res && res.breakfast && res.breakfast.map((el) => collazione.push(el));
  //       res && res.lunch && res.lunch.map((el) => pranzo.push(el));
  //       res && res.dinner && res.dinner.map((el) => cena.push(el));
  //       res && res.perekus1 && res.perekus1.map((el) => snack.push(el));
  //       res && res.perekus2 && res.perekus2.map((el) => snack.push(el));

  //       !res &&
  //         setShowPopup({
  //           visible: true,
  //           heading: "ATTENZIONE",
  //           message: "Stiamo cercando dei piatti migliori per te!",
  //           button: "Riprova",
  //           onClose: () => setShowPopup({ visible: false }),
  //           onClick: () => {
  //             generateCustomMenu();
  //             setGeneratedLoading(true);
  //             setShowPopup({ visible: false });
  //           },
  //         });

  //       dispatch(setMenuEmpty());
  //       dispatch(setGeneratedMenu({ collazione, pranzo, cena, snack }));
  //     })
  //     .catch((error) => {
  //       setGeneratedLoading(false);
  //       console.log("error", error.message);
  //       setShowPopup({
  //         visible: true,
  //         heading: "ATTENZIONE",
  //         message: "Stiamo cercando dei piatti migliori per te!",
  //         button: "Riprova",
  //         onClose: () => setShowPopup({ visible: false }),
  //         onClick: () => {
  //           generateCustomMenu();
  //           setGeneratedLoading(true);
  //           setShowPopup({ visible: false });
  //         },
  //       });
  //     });
  // };

  const generateMenu = () => {
    api
      .get(
        `/login/generator_new_web_get?T_carboidrati=${
          personalDish.macro_carbo
        }&T_grassi=${personalDish.macro_grasi}&T_proteine=${
          personalDish.macro_prote
        }&allergen=${listOrEmpty}&allergenContent=${JSON.stringify({
          data: activeAllergy,
        })}`
      )
      .then((result) => {
        const res = result.data.json;
        setGeneratedLoading(false);
        const collazione = [];
        const pranzo = [];
        const cena = [];
        const snack = [];

        res &&
          res.collazione &&
          res.collazione.map((el) => collazione.push(el));
        res && res.pranzo && res.pranzo.map((el) => pranzo.push(el));
        res && res.cena && res.cena.map((el) => cena.push(el));
        res && res.spuntini && res.spuntini.map((el) => snack.push(el));

        !res &&
          setShowPopup({
            visible: true,
            heading: "ATTENZIONE",
            message: "Stiamo cercando dei piatti migliori per te!",
            button: "Riprova",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => {
              generateMenu();
              setGeneratedLoading(true);
              setShowPopup({ visible: false });
            },
          });

        dispatch(setMenuEmpty());
        dispatch(setGeneratedMenu({ collazione, pranzo, cena, snack }));
      })
      .catch((error) => {
        setGeneratedLoading(false);
        console.log("error", error);
        setShowPopup({
          visible: true,
          heading: "ATTENZIONE",
          message: "Stiamo cercando dei piatti migliori per te!",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            generateMenu();
            setGeneratedLoading(true);
            setShowPopup({ visible: false });
          },
        });
      });
  };

  const colDish = menuDish?.collazione;
  const colFood = menuFood?.collazione;
  const prDish = menuDish?.pranzo;
  const prFood = menuFood?.pranzo;
  const ceDish = menuDish?.cena;
  const ceFood = menuFood?.cena;
  const snDish = menuDish?.snack;
  const snFood = menuFood?.snack;

  const sumCollazioneEnergy = sumEnergy(colDish, colFood);
  const sumPranzoEnergy = sumEnergy(prDish, prFood);
  const sumCenaEnergy = sumEnergy(ceDish, ceFood);
  const sumSnackEnergy = sumEnergy(snDish, snFood);
  const sumCollazioneCarbs = sumCarbs(colDish, colFood);
  const sumPranzoCarbs = sumCarbs(prDish, prFood);
  const sumCenaCarbs = sumCarbs(ceDish, ceFood);
  const sumSnackCarbs = sumCarbs(snDish, snFood);
  const sumCollazioneProte = sumProte(colDish, colFood);
  const sumPranzoProte = sumProte(prDish, prFood);
  const sumCenaProte = sumProte(ceDish, ceFood);
  const sumSnackProte = sumProte(snDish, snFood);
  const sumCollazioneGras = sumGras(colDish, colFood);
  const sumPranzoGras = sumGras(prDish, prFood);
  const sumCenaGras = sumGras(ceDish, ceFood);
  const sumSnackGras = sumGras(snDish, snFood);

  const totalEnergy =
    sumCollazioneEnergy + sumPranzoEnergy + sumCenaEnergy + sumSnackEnergy;
  const totalCarbs =
    sumCollazioneCarbs + sumPranzoCarbs + sumCenaCarbs + sumSnackCarbs;
  const totalProte =
    sumCollazioneProte + sumPranzoProte + sumCenaProte + sumSnackProte;
  const totalGras =
    sumCollazioneGras + sumPranzoGras + sumCenaGras + sumSnackGras;

  const fix = (nr) => {
    const num = Number(nr);
    if (num % 1 === 0) {
      return num;
    } else {
      return num.toFixed(1);
    }
  };
  const rnd = (nr) => Math.round(nr);
  const plusTenPercent = (nr) => {
    const num = parseInt(nr);
    return num * 0.1 + num;
  };

  const calcPercentage = (pr, nr) => {
    return (nr * 100) / pr;
  };

  const activateRefeedDay = () => {
    const options = personalDish.options;
    api
      .post("/profile/me_change_personas_dish", {
        json: {
          ...personalDish,
          options: {
            ...options,
            lastUpdate: moment().format("L"),
            used: +options.used + 1,
          },
        },
      })
      .then((response) => {
        console.log(response.data);
        dispatch(setPersonalDishLoading(true));
        dispatch(fetchPersonalDish());
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container pb-4">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      {location.pathname === "/percorso/alimentazione" && (
        <div>
          <Card
            style={{ backgroundColor: refeedDay && Colors.primaryLightShadow }}
          >
            <div className="d-flex justify-content-between">
              <p className="heading">Strategia Alimentare</p>
              <IntroAlimentazioneModal
                openModal={
                  <div className="d-flex align-items-center allenamenti-video-intro">
                    <p className="allenamenti-video-text">Video Introduzione</p>
                    <IoPlayOutline size={25} color={Colors.primary} />
                  </div>
                }
              />
            </div>
            <div className="d-flex align-items-baseline">
              <p className="sub-heading">{personalDish?.strategies}</p>
              {refeedDay && <p className="refeed-text">REFEED DAY</p>}
            </div>
            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="d-flex justify-content-center">
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_carbo,
                        rnd(totalCarbs)
                      )}
                      text={`${rnd(totalCarbs)}`}
                      color={
                        rnd(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        rnd(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.greenLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        rnd(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">carboidrati</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_carbo}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_grasi,
                        rnd(totalGras)
                      )}
                      text={`${rnd(totalGras)}`}
                      color={
                        rnd(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        rnd(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.redLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        rnd(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">grassi</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_grasi}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_prote,
                        rnd(totalProte)
                      )}
                      text={`${rnd(totalProte)}`}
                      color={
                        rnd(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        rnd(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.redLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        rnd(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">proteine</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_prote}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={100}
                      text={`${rnd(totalEnergy)}`}
                      color={Colors.primary}
                      textColor={Colors.primary}
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">KCAL</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_kcal}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-12 d-flex flex-xl-column justify-content-between ">
                <div className="generate-wrapper d-flex justify-content-xl-end">
                  {personalDish?.options &&
                    personalDish.strategies === "Metodo Misto" &&
                    +personalDish?.options.used <
                      +personalDish?.options.freeDay &&
                    !refeedDay && (
                      <Button
                        type="outline"
                        name="Refeed Day"
                        style={{ width: 200 }}
                        onClick={() => {
                          setShowPopup({
                            visible: true,
                            heading: "ATTENZIONE",
                            message:
                              "Stai per attivare il tuo Refeed Day, sei sicura di voler procedere?",
                            button: "SI",
                            onClose: () => setShowPopup({ visible: false }),
                            onClick: () => {
                              activateRefeedDay();
                              setShowPopup({ visible: false });
                            },
                          });
                        }}
                      />
                    )}
                </div>
                <div className="generate-wrapper d-flex justify-content-xl-end">
                  <Button
                    style={{ width: 200 }}
                    name={
                      generatedLoading ? (
                        <ClipLoader
                          color={Colors.white}
                          loading={generatedLoading}
                          size={20}
                        />
                      ) : (
                        "Genera Menu"
                      )
                    }
                    type={"primary"}
                    onClick={() => {
                      setShowPopup({
                        visible: true,
                        heading: "ATTENZIONE",
                        message:
                          "Sei sicuro di voler aggiornare il tuo menu? Tutti i piatti inseriti saranno cambiati.",
                        button: "OK",
                        onClose: () => setShowPopup({ visible: false }),
                        onClick: () => {
                          setGeneratedLoading(true);
                          customCategoryMenuItem === "Personalizzato"
                            ? generateMenu()
                            : generateCustomMenu();
                          setShowPopup({ visible: false });
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="heading heading-card mb-2 mt-xl-1 mt-lg-3 mt-md-3">
                Custom Menu
              </p>
              <div className="d-flex flex-wrap">
                {customCategoryMenu.map((item) => (
                  <div
                    key={item}
                    onClick={() => setCustomCategoryMenuItem(item)}
                    className={
                      customCategoryMenuItem === item
                        ? "filter-itemActive"
                        : "filter-item"
                    }
                  >
                    <p
                      className={
                        customCategoryMenuItem === item
                          ? "filter-itemTextActive"
                          : "filter-itemText"
                      }
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <p className="heading heading-card mb-2 mt-xl-1 mt-lg-3 mt-md-3">
                Escludi Alergeni
              </p>
              <div className="d-flex">
                <Filter />
              </div>
            </div>
          </Card>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Colazione</p>
                  <div className="d-flex">
                    <p className="macros-items">
                      Kcal {rnd(sumCollazioneEnergy)}
                    </p>
                    <p className="macros-items">
                      Carb {rnd(sumCollazioneCarbs)}
                    </p>
                    <p className="macros-items">
                      Grassi {rnd(sumCollazioneGras)}
                    </p>
                    <p className="macros-items">
                      Proteine {rnd(sumCollazioneProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.collazione.length > 0 &&
                    menuDish.collazione.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "collazione",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.collazione.length > 0 &&
                    menuFood.collazione.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.carboidrati)}
                              gras={fix(item.grassi)}
                              prot={fix(item.proteine)}
                              labelText={`${fix(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "collazione",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("collazione"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Pranzo</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {rnd(sumPranzoEnergy)}</p>
                    <p className="macros-items">Carb {rnd(sumPranzoCarbs)}</p>
                    <p className="macros-items">Grassi {rnd(sumPranzoGras)}</p>
                    <p className="macros-items">
                      Proteine {rnd(sumPranzoProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.pranzo.length > 0 &&
                    menuDish.pranzo.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "pranzo",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.pranzo.length > 0 &&
                    menuFood.pranzo.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.carboidrati)}
                              gras={fix(item.grassi)}
                              prot={fix(item.proteine)}
                              labelText={`${fix(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "pranzo",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("pranzo"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Cena</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {rnd(sumCenaEnergy)}</p>
                    <p className="macros-items">Carb {rnd(sumCenaCarbs)}</p>
                    <p className="macros-items">Grassi {rnd(sumCenaGras)}</p>
                    <p className="macros-items">Proteine {rnd(sumCenaProte)}</p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.cena.length > 0 &&
                    menuDish.cena.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "cena",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.cena.length > 0 &&
                    menuFood.cena.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.carboidrati)}
                              gras={fix(item.grassi)}
                              prot={fix(item.proteine)}
                              labelText={`${fix(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "cena",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("cena"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Snack</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {rnd(sumSnackEnergy)}</p>
                    <p className="macros-items">Carb {rnd(sumSnackCarbs)}</p>
                    <p className="macros-items">Grassi {rnd(sumSnackGras)}</p>
                    <p className="macros-items">
                      Proteine {rnd(sumSnackProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.snack.length > 0 &&
                    menuDish.snack.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "snack",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.snack.length > 0 &&
                    menuFood.snack.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          button="Aggiunto nel tuo menu"
                          menu
                          disabled
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.carboidrati)}
                              gras={fix(item.grassi)}
                              prot={fix(item.proteine)}
                              labelText={`${fix(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeFood({
                                category: "snack",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("snack"));
                      navigate("selectPlate");
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <Outlet />
    </div>
  );
}
