import React, { useState } from "react";
import bg from "../assets/bg-fespa.webp";
import Input from "../components/Input";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { api } from "../api/api";
import Popup from "../components/Popup";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkPassword = () => newPassword === repeatPassword && newPassword;

  const resetPassword = async () => {
    api
      .post("/login/setNewPassword", {
        email: email,
        password: newPassword,
        otp: code,
      })
      .then((response) => {
        if (!response.data.msg) {
          setShowPopup({
            visible: true,
            heading: "ATTENTION",
            message: "Wrong email or code, Please try again",
            button: "Try Again",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => setShowPopup({ visible: false }),
          });
        } else {
          setShowPopup({
            visible: true,
            heading: "ATTENTION",
            message:
              "Your password was restored, Please log in with your email and new password!",
            button: "OK",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => navigate(-2),
          });
        }
      })
      .catch((err) => {
        setShowPopup({
          visible: true,
          heading: "ATTENTION",
          message: "Wrong email or code, Please try again",
          button: "Try Again",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });
      });
  };

  return (
    <div className="login">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <img src={bg} alt="Fespa Background" className="login-bg" />
      <div className="login-wrapper">
        <p className="login-logo">fespa</p>
        <p className="login-description">Imposta una nuova password</p>
        <Input
          placeholder="Login Email"
          hint="Log-in ricevuto sulla mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder="Codice"
          hint="Codice temporaneo ricevuto sulla mail"
          onChange={(e) => setCode(e.target.value)}
        />
        <Input
          placeholder="Nuova Password"
          hint="New password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          placeholder="Ripeti la nuova Password"
          hint="Repeat New password"
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
        <Button
          name="Reset Password"
          type="black"
          onClick={() => {
            checkPassword()
              ? resetPassword()
              : setShowPopup({
                  visible: true,
                  heading: "ATTENTION",
                  message: "Repeat new password correctly and try again",
                  button: "Try Again",
                  onClose: () => setShowPopup({ visible: false }),
                  onClick: () => setShowPopup({ visible: false }),
                });
          }}
        />
      </div>
    </div>
  );
}
