import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchParams } from "../redux/profile";
import Card from "../components/Card";
import Button from "../components/Button";
import ProgressCircle from "../components/ProgressCircle";
import Colors from "../constants/Colors";
import CheckFotoCard from "../components/CheckFotoCard";
import Loader from "../components/Loader";
import moment from "moment";
import CheckModal from "../components/CheckModal";
import IntroCheckModal from "../components/IntroCheckModal";
import { IoPlayOutline } from "react-icons/io5";

export default function Parametri(props) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.profile.params);
  const paramsLoading = useSelector((state) => state.profile.paramsLoading);

  useEffect(() => {
    dispatch(fetchParams());
  }, []);

  if (paramsLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={paramsLoading} size={50} />
      </div>
    );
  }

  if (!params?.first) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className=" mt-5"
      >
        <CheckModal
          openModal={<Button type={"primary"} name="+ Aggiungi Check" />}
        />
      </div>
    );
  }

  const today = moment().format("L");
  let nextCheck = "";
  if (params?.last) {
    nextCheck = moment(params?.last.updated_at).add(7, "days").format("L");
  } else {
    nextCheck = moment().add(7, "days").format("L");
  }

  const lastUpdate = moment(params?.last?.updated_at).format("L");

  const diffKg = params?.first?.kg_diff;
  const diffFat = params?.first?.massa_grassa_diff;

  const checkDiff = (nr) => {
    if (nr === 0 || !nr) {
      return "no changes";
    } else if (nr > 0) {
      return `+${nr}cm`;
    } else {
      return `${nr}cm`;
    }
  };

  const checkDiffKg = (nr) => {
    if (nr === 0 || !nr) {
      return "0";
    } else if (nr > 0) {
      return `+${nr}kg`;
    } else {
      return `${nr}kg`;
    }
  };

  const checkDiffFat = (nr) => {
    if (nr === 0 || !nr) {
      return "0";
    } else if (nr > 0) {
      return `+${nr}%`;
    } else if (nr < 0) {
      return `${nr}%`;
    }
  };

  const checkColor = (nr) => {
    if (nr === 0 || !nr) {
      return Colors.gray;
    } else if (nr > 0) {
      return Colors.red;
    } else {
      return Colors.green;
    }
  };

  const checkShadowColor = (nr) => {
    if (nr === 0 || !nr) {
      return Colors.grayLight;
    } else if (nr > 0) {
      return Colors.redLightShadow;
    } else {
      return Colors.greenLightShadow;
    }
  };

  return (
    <div className="container parametri pb-3">
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <p className="heading">Check Parametri</p>
          <div>
            {moment(nextCheck) >= moment(today) ? (
              <Button
                type={"primary"}
                name={`Prossimo Check ${nextCheck}`}
                style={{ backgroundColor: Colors.primaryLightShadow }}
              />
            ) : (
              <CheckModal
                openModal={<Button type={"primary"} name="+ Aggiungi Check" />}
              />
            )}
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          <div className="col-6 col-xl-6 col-lg-12 col-md-12">
            <div className="d-flex align-items-center justify-content-evenly">
              <div className="parametri-progress text-center">
                <p className="parametri-progress-heading">
                  parametri personali
                </p>
                <div className="d-flex">
                  <div className="parametri-progress-wrapper">
                    <ProgressCircle
                      value={100}
                      text={params?.first?.kg + "kg"}
                      color={Colors.primary}
                      textColor={Colors.primary}
                      shadowColor={Colors.primaryLightShadow}
                    />
                    <div className="parametri-progress-details text-center">
                      <p className="parametri-progress-subtext">primo check</p>
                    </div>
                  </div>
                  <div className="parametri-progress-wrapper">
                    <ProgressCircle
                      value={30}
                      text={checkDiffKg(diffKg)}
                      color={checkColor(diffKg)}
                      textColor={checkColor(diffKg)}
                      shadowColor={checkShadowColor(diffKg)}
                    />
                    <div className="parametri-progress-details text-center">
                      <p className="parametri-progress-subtext">ultimo check</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="parametri-progress text-center">
                <p className="parametri-progress-heading">
                  Percentuale Massa grassa
                </p>
                <div className="d-flex">
                  <div className="parametri-progress-wrapper">
                    <ProgressCircle
                      value={parseInt(params?.first?.massa_grassa)}
                      text={params.first.massa_grassa + "%"}
                      color={Colors.primary}
                      textColor={Colors.primary}
                      shadowColor={Colors.primaryLightShadow}
                    />
                    <div className="parametri-progress-details text-center">
                      <p className="parametri-progress-subtext">primo check</p>
                    </div>
                  </div>
                  <div className="parametri-progress-wrapper">
                    <ProgressCircle
                      value={20}
                      text={checkDiffFat(diffFat)}
                      color={checkColor(diffFat)}
                      textColor={checkColor(diffFat)}
                      shadowColor={checkShadowColor(diffFat)}
                    />
                    <div className="parametri-progress-details text-center">
                      <p className="parametri-progress-subtext">ultimo check</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-xl-6 col-lg-12 col-md-12">
            <div className="parametri-items d-flex align-items-center justify-content-evenly">
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Collo</p>
                <p className="parametri-items-value">{`${params?.last?.collo}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.collo_diff) }}
                >
                  {params.first.collo_diff
                    ? checkDiff(parseFloat(params.first.collo_diff).toFixed(1))
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Braccio</p>
                <p className="parametri-items-value">{`${params?.last?.braccio}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.braccio_diff) }}
                >
                  {params.first.braccio_diff
                    ? checkDiff(
                        parseFloat(params.first.braccio_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Vita</p>
                <p className="parametri-items-value">{`${params.last.vita}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.vita_diff) }}
                >
                  {params.first.vita_diff
                    ? checkDiff(parseFloat(params.first.vita_diff).toFixed(1))
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Fianchi</p>
                <p className="parametri-items-value">{`${params.last.fianchi}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.fianchi_diff) }}
                >
                  {params.first.fianchi_diff
                    ? checkDiff(
                        parseFloat(params.first.fianchi_diff).toFixed(1)
                      )
                    : ""}
                </p>
              </div>
              <div className="parametri-items-wrapper">
                <p className="parametri-items-heading">Coscia</p>
                <p className="parametri-items-value">{`${params.last.coscia}cm`}</p>
                <p
                  className="parametri-items-changes"
                  style={{ color: checkColor(params.first.coscia_diff) }}
                >
                  {params.first.coscia_diff
                    ? checkDiff(parseFloat(params.first.coscia_diff).toFixed(1))
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {(params.first.notes || params.last.notes) && (
        <Card>
          <p className="heading heading-card">Note</p>
          <p className="contentText">{params.first.notes || params.last.notes}</p>
        </Card>
      )}
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <p className="heading">Check {lastUpdate}</p>
          <IntroCheckModal
            openModal={
              <div className="d-flex align-items-center allenamenti-video-intro">
                <p className="allenamenti-video-text">Video Introduzione</p>
                <IoPlayOutline size={25} color={Colors.primary} />
              </div>
            }
          />
        </div>
        <div className="row justify-content-between justify-content-lg-center justify-content-md-center">
          <div className="col-4 col-xl-4 col-md-8">
            <CheckFotoCard
              heading={"Check Foto Frontale"}
              sourceLeft={params?.first?.front_img}
              sourceRight={params?.last?.front_img}
            />
          </div>
          <div className="col-4 col-xl-4 col-md-8">
            <CheckFotoCard
              heading={"Check Foto Laterale"}
              sourceLeft={params?.first?.side_img}
              sourceRight={params?.last?.side_img}
            />
          </div>
          <div className="col-4 col-xl-4 col-md-8">
            <CheckFotoCard
              heading={"Check Foto Spalle"}
              sourceLeft={params?.first?.back_img}
              sourceRight={params?.last?.back_img}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
